import { event } from 'jquery'
import { array } from 'prop-types'
import React, {useState, useEffect} from 'react'
import HubspotForm from 'react-hubspot-form'
import PricingPageAccordion from '../PricingPageAccordion'

const ModernPricingB = () => {
    const [bool, setbool] = useState(false)
    const [openAcc, setOpenAcc] = useState('rr')
    const [accArr, setAccarr] = useState([])
    const [top, setTop] = useState(137) 
  
    const handleAccordion = (id, e) => {
      if(!(accArr.includes(id))){
        setAccarr([...accArr, id])
      }
      else setAccarr(accArr.filter((item)=> item !== id))
    }
    
  
    return (
      <>
      <section className='hero-pricing w-full flex justify-start items-center flex-col relative py-6 xl:py-16 text-center'>
          <h2 className='font-bold text-purple-100 text-4xl xl:text-7xl py-5'>Plans and Pricing</h2>
          <p className='text-purple-100 text-lg xl:text-xl px-3'>Choose the plan that best fits your requirement</p>
          <p className='text-purple-100 text-xl xl:text-2xl mt-6 px-5 xl:px-0'>Trusted by <span className='font-bold'>700+</span> companies of all sizes including<span class="relative xl:text-3xl"> Fortune 500 <img class="absolute -bottom-2 right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" alt="hero-stroke" /></span>companies</p>
      </section>
      <section className='relative flex justify-center whitespace max-w-7xl mx-auto px-5 xl:px-0'>
          <div className='pricelabels xl:grid grid-cols-1 xl:grid-cols-3 gap-12 py-10 xl:pb-2'>
              <div className='flex flex-col relative bg-white rounded-xl shadow-xl p-7 my-6 text-left xl:transform hover:scale-105 transition:transform duration-300'>
                  <h2 className='text-4xl font-bold text-gray-500 w-full'>Grow</h2>
                  <p className='py-5 text-sm'>Start with Rewards & Recognition</p>
                  <a href='#register' target="_blank" className='w-full text-center border-orange border rounded text-orange p-3 my-5 hover:bg-orange hover:text-white transition:hover duration-150'>Request a Quote</a>
                  <div className='flex flex-col items-start justify-center my-6'>
                      <h2 className='text-gray-500' style={{ fontFamily: 'lato' }}>Includes:</h2>
                      <ul className='flex flex-col w-full'>
                        <li className='flex w-full mt-5 pt-3 pb-1 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Peer to peer recognition</p></div>
                        </li>
                        <li className='flex w-full mt-5 pt-3 pb-1 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Nomination based awards</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Automated recognition for birthdays and work anniversaries</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Global redemption options- Amazon store, gift vouchers, experiences, merchandise</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Actionable insights and analytics</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>User-friendly mobile and web apps</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Best-in-class customer support</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Available in 70+ countries</p></div>
                        </li>
                      </ul>
                  </div>
                  <div className='my-3 flex items-center xl:absolute xl:bottom-6'>
                    <a href = "#comparePackages" className='text-xl text-orange' style={{ fontFamily: 'lato' }}>Explore all features <span className='m-3'>&gt;</span></a>
                  </div>
              </div>

              <div className='flex flex-col bg-white rounded-xl shadow-xl text-left my-6 xl:transform hover:scale-105 transition:transform duration-300'>
                <div className='bg-orange flex flex-col justify-center text-white p-7 pb-0 rounded-t-xl relative'>
                  <div className='absolute' style={{ top: '-1px', right: '-1px' }}><img loading= "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/popular-badge.png" height="115" width= "115" /></div>
                  <h2 className='text-4xl font-bold text-white w-full'>Transform <span className='text-2xl' style={{ fontFamily: 'lato' }}></span></h2>
                  <p className='py-5 text-sm text-white'>Scale up your Rewards & Recognition program</p>
                  <a href = "#register" target="_blank" className='w-full text-center border-white border rounded text-white p-3 my-5 hover:bg-white hover:text-orange transition:hover duration-150'>Request a Quote</a>
                </div>
                <div className='flex flex-col items-start justify-center p-7'>
                      <h2 className='text-gray-500' style={{ fontFamily: 'lato' }}>Includes everything in <span className= "font-bold">Grow,</span> plus :</h2>
                      <ul className='flex flex-col w-full'>
                        <li className='flex w-full mt-5 py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Nomination based panel awards</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>HRMS/SSO integrations</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Advanced insights and analytics</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Budget management</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Manager dashboard</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Available in 5 languages</p></div>
                        </li>
                        <div className='my-7'>
                          <p className='text-sm font-bold'>Included in the plan-</p>
                          <div className='flex mt-4 mb-0 flex-col'>
                            <div className= "flex items-center">
                            <div className='rounded bg-purple-100 p-1 border border-purple-800'>
                              <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/04/pulse.svg" class="transform scale-75" alt="Vantage Rewards" width="25" height="5" />
                            </div>
                            <h2 className='text-sm pl-3' style={{ fontFamily: 'lato' }}>Vantage Pulse</h2>
                            </div>
                            <li className='flex w-full py-3 ml-1 pl-6'>
                              <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                              <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Standard questions</p></div>
                            </li>
                          </div>
                          <div className='flex my-0 flex-col'>
                            <div className= "flex items-center">
                            <div className='rounded bg-purple-100 p-1 border border-purple-800'>
                              <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/04/Perks.svg" class="transform scale-75" alt="Vantage Rewards" width="25" height="5" />
                            </div>
                            <h2 className='text-sm pl-3' style={{ fontFamily: 'lato' }}>Vantage Perks</h2>
                            </div>
                            <li className='flex w-full py-3 ml-1 pl-6'>
                              <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                              <div className='w-10/12'><p className='pl-2 m-0 text-sm'>1000 offers</p></div>
                            </li>
                          </div>
                        </div>
                      </ul>
                  </div>
                  <div className='my-6 flex items-center p-7 xl:absolute xl:-bottom-4'>
                    <a href= "#comparePackages" className='text-xl text-orange' style={{ fontFamily: 'lato' }}>Explore all features <span className='m-3'>&gt;</span></a>
                  </div>
              </div>
              
              <div className='flex flex-col bg-white rounded-xl shadow-xl p-7 my-6 text-left xl:transform hover:scale-105 transition:transform duration-300' >
                  <h2 className='text-4xl font-bold text-gray-500 w-full'>Enterprise</h2>
                  <p className='py-5 text-sm'>Curated especially for your organizational needs</p>
                  <a href='#register' target="_blank" className='w-full text-center border-orange border rounded text-orange p-3 my-6 hover:bg-orange hover:text-white transition:hover duration-150'>Request a Quote</a>
                  <div className='flex flex-col items-start justify-center mt-6 mb-0'>
                      <h2 className='text-gray-500' style={{ fontFamily: 'lato' }}>Includes everything in <span className = "font-bold">Transform,</span> plus :</h2>
                      <ul className='flex flex-col w-full'>
                        <li className='flex w-full mt-5 py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Team awarding</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Service yearbook</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Custom integrations</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Multi-purpose wallet</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Dedicated account manager</p></div>
                        </li>
                        <li className='flex w-full py-3 ml-1'>
                          <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                          <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Available in 15+ languages</p></div>
                        </li>
                        <div className='my-7'>
                          <p className='text-sm font-bold'>Included in the plan-</p>
                          <div className='flex mt-4 mb-4 flex-col'>
                            <div className= "flex items-center">
                            <div className='rounded bg-purple-100 p-1 border border-purple-800'>
                              <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/04/pulse.svg" class="transform scale-75" alt="Vantage Rewards" width="25" height="5" />
                            </div>
                            <h2 className='text-sm pl-3' style={{ fontFamily: 'lato' }}>Vantage Pulse</h2>
                            </div>
                            <li className='flex w-full py-2 ml-1 pl-6'>
                              <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                              <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Unlimited questions</p></div>
                            </li>
                            <li className='flex w-full py-0 ml-1 pl-6'>
                              <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                              <div className='w-10/12'><p className='pl-2 m-0 text-sm'>Industry benchmarking</p></div>
                            </li>
                          </div>
                          <div className='flex my-0 flex-col'>
                            <div className= "flex items-center">
                            <div className='rounded bg-purple-100 p-1 border border-purple-800'>
                              <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1649142710/gatsbycms/uploads/2022/04/Perks.svg" class="transform scale-75" alt="Vantage Rewards" width="25" height="5" />
                            </div>
                            <h2 className='text-sm pl-3' style={{ fontFamily: 'lato' }}>Vantage Perks</h2>
                            </div>
                            <li className='flex w-full py-3 ml-1 pl-6'>
                              <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                              <div className='w-10/12'><p className='pl-2 m-0 text-sm'>10000+ offers</p></div>
                            </li>
                          </div>
                        </div>
                      </ul>
                  </div>
                  <div className='m-0 flex items-center p-4 xl:absolute xl:bottom-6'>
                    <a href= "#comparePackages" className='text-xl text-orange' style={{ fontFamily: 'lato' }}>Explore all features <span className='m-3'>&gt;</span></a>
                  </div>
              </div>
            </div>
      </section>
  
      <div className='max-w-7xl hidden xl:grid grid-cols-3 mx-auto'>
          <p className='text-sm text-left italic pr-9'><sup>*</sup> Nominal one-time setup cost is applicable on all plans.</p>
      </div>
  
  {/* <section className='my-10 xl:mt-32 xl:mb-28'>
      <h2 class="section-title text-center">Building reward-driven culture <span class="relative"> <img class="absolute bottom-0 right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /></span> at workplaces</h2>
        <div class="w-full flex flex-col justify-center items-center py-2 px-0 lg:px-6">
          <div class="c-logo-strip w-full lg:w-11/12 py-10 my-8 md:my-10 lg:my-16 2xl:my-20"></div>
          <a href="/corporates/" class="text-purple-500 font-bold flex flex justify-center text-xl items-center mb-6 ">See more clients <svg class="w-6 h-6 top-0" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg></a>
        </div>
      </section> */}

      
        {/* <section className='w-full flex flex-col justify-center items-center px-5 py-5 xl:py-16 bg-gray-100'>
            <div className='max-w-7xl my-5'>
                <div id='clientsSection'></div>
            </div>
        </section> */}
  
      <div id = "#pricing-page-accordion"></div>
  
      <div id="register" className="card-1 hubspot-btn w-full md:max-w-7xl p-10 rounded-xl shadow  text-gray-900 mx-auto mt-20 mb-10">
                    <h2 class = "mt-16 md:mt-0 md:mb-0 mb-2 font-bold text-2xl md:text-4xl text-gray-800 text-center py-10">Request for pricing details</h2>
                    {/* <p class = "text-sm mb-8 text-center mt-2 pb-5">Quick! Book Your Slot for the Vantage Circle Webinar Series!</p> */}
                            <div class = "flex justify-center items-center">
                                <HubspotForm
                                portalId='6153052'
                                formId='d7303dc2-49a8-4385-a292-e7dca3f454e2'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                            />

                            </div>
                    <p class = "text-sm text-center">We safeguard your <a href="/privacy-policy/" class = "text-purple-500">Privacy</a></p>
        </div>                

      <section class="w-full flex place-content-center px-6 bg-gray-100 mt-20" id="faqs">
     <div class="container max-w-full w-full lg:w-11/12 xl:w-10/12 2xl:w-8/12 mx-auto md:px-6 md:pb-16 md:pt-20 flex flex-col justify-around items-center">
        <div class="text-center pb-5 md:pb-14 pt-10 my-1 md:pt-0 md:mr-5">
           <h2 class="text-center text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-bold text-gray-500"><span class="font-light text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl">Got Questions?<br/> </span>Read our <span class="underlined xl:big">FAQs.</span></h2>
        </div>
        <div class="career-faq p-2 md:p-5 w-full mb-10 md:ml-5">
           <div class="px-1 py-5 toc-detail">
              <div class="container max-w-full">
                 <div class="acc relative">
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">1. What ROI can I expect from Vantage Rewards?</div>
                       <div class="acc__panel" style={{display:'block', marginTop:'.2rem', padding:'0.8rem'}}>The ROI of rewards and recognition platforms is immense. It not only shapes your company culture but builds trust, respect, and productivity among them. The idea is to provide employees with the best employee experience and empower them to build a workforce that thrives together. Vantage Rewards does exactly that and improves your company’s overall engagement, productivity, retention, and more.</div>
                    </div>
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">2. Which subscription plan is right for me?</div>
                       <div class="acc__panel" style={{display:'none;', marginTopn: '.2rem', padding:'0.8rem'}}>
                       Each one of our plans has been specially curated, keeping in mind the needs of an organization from starting their recognition journey to reaping the benefits of their current efforts.<br/><br/>
   
   Our <span className= "font-bold" >'Grow’</span> plan will work best for you if you have just started your recognition journey and want to start building a culture of appreciation and show your employees 'We care'. <br/><br/>
   
   Our <span className= "font-bold" >‘Transform’</span> plan, which also happens to be the most popular plan, goes beyond recognition. It is suitable for organizations that want to scale up their RnR efforts now they have seen the impact a simple 'Thank you,' and a generous award can make. <br/><br/>
   
   Our <span className= "font-bold" >‘Enterprise’</span> plan dives deep into the rewards and recognition space by bundling all of our services together to give your employees the ultimate RnR experience. It will be curated especially as per your organizational needs. Get in touch to know more.
  
                       </div>
                    </div>
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">3.  Is it possible to upgrade Plans anytime?</div>
                       <div class="acc__panel" style={{display:'none;', marginTopn: '.2rem', padding:'0.8rem'}}>You can add features & functionalities or upgrade at any time. Get in touch with our Client Success Team to see what works for your business and how we can help you achieve that seamlessly.</div>
                    </div>
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">4. Can I put add-on features to my subscription plan later on?</div>
                       <div class="acc__panel" style={{display:'none;', marginTopn: '.2rem', padding:'0.8rem'}}>Yes, definitely. We have categorized our feature upgrades into free as well as ones which may require commercials. Get in touch with the Client Success team for any addition you want to make to your current plan. We will be more than happy to enhance your experience.</div>
                    </div>              
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">5. What is the turnaround time for my queries?</div>
                       <div class="acc__panel" style={{display:'none;', marginTopn: '.2rem', padding:'0.8rem'}}>For day to day user queries and technical support you can reach out to our team at <a href="mailto:support@vantagecircle.com">support@vantagecircle.com</a>. Queries are acknowledged immediately and tickets are created. Most queries are usually resolved within 48 hours.</div>
                    </div>
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">6. What is the standard validity of the agreement?</div>
                       <div class="acc__panel" style={{display:'none;', marginTopn: '.2rem', padding:'0.8rem'}}>The agreement sign up has an option of 1 year or above. </div>
                    </div>
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">7. Will there be a change in pricing upon renewal of the agreement?</div>
                       <div class="acc__panel" style={{display:'none;', marginTopn: '.2rem', padding:'0.8rem'}}>The pricing upon renewal might vary as per the terms & conditions set up during the agreement. For any such queries please get in touch with your aligned Client Success Specialist.</div>
                    </div>
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">8. Will there be any additional commercials involved on any customizations of the module?</div>
                       <div class="acc__panel" style={{display:'none;', marginTopn: '.2rem', padding:'0.8rem'}}>Yes, additional commercials might apply depending on the feasibility and mantime involved.</div>
                    </div>
                    <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                       <div class="acc__title text-base haschildren toc-heading">9. What’s your cancellation policy?</div>
                       <div class="acc__panel" style={{display:'none;', marginTopn: '.2rem', padding:'0.8rem'}}>We would absolutely hate to see you go and would want to resolve issues(if any) and enhance your experience with us. If you still want to cancel your subscription, get in touch with your aligned Client Success Specialist.</div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </section>
      </>
    )
  }

export default ModernPricingB