import React from 'react'
import ModernPricingB from '../components/ModerPriceFolder/ModernPricingB'
import "../styles/global.css"
import Layout from "../components/layout"
import Helmet from "react-helmet"

const pricing = ({ location }) => {
  return (
    <Layout location={location}>
         <Helmet>
                <title>Pricing - Vantage Circle </title>

                <meta name="description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
                <meta property="twitter:title" content="Pricing | Vantage Circle" />
                <meta property="twitter:description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/pricing/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Pricing | Vantage Circle" />

                <meta property="og:description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
                <link rel="canonical" href="https://www.vantagecircle.com/pricing/"></link>
            </Helmet>
    <ModernPricingB />
  </Layout>
  )
}

export default pricing